export class coreUrlList {
  comm = "demo.appdukaan.com:5007/v2";
  driver1 = "demo.appdukaan.com:5013/v2";
  onDemand = "";
  // File Upload
  uploadFile: string = `${this.comm}/upload/upload`;

  //  App Setting
  appSetting: string = `${this.comm}/admin/appSetting`;
  getAppSetting: string = `${this.comm}/admin/getAppSetting`;

  // Auth
  login: string = `${this.comm}/admin/login`;
  forgotPasswordSendEmail1: string = `${this.comm}/admin/forgotPassword`;
  checkToken: string = `${this.comm}/admin/panel/checkToken`;
  resetPassword: string = `${this.comm}/admin/panel/resetpass`;

  // Payment SMS Methods
  getPaymentMethods: string = `${this.driver1}/admin/getPaymentMethods`;
  getPaymentMode: string = `${this.driver1}/admin/getPaymentModes`;
  getSmsMethods: string = `${this.driver1}/admin/getSmsMethods`;
  managePaymentMethods: string = `${this.driver1}/admin/managePaymentMethods`;
  manageSmsMethods: string = `${this.driver1}/admin/manageSmsMethods`;
  managefeatureoff: string = `${this.driver1}/admin/manageFeatureOff`;

  // Admin
  getAdminProfile: string = `${this.comm}/admin/getAdminProfile`;
  updateProfile: string = `${this.comm}/admin/updateProfile`;
  changePassword: string = `${this.comm}/admin/adminChangePass`;

  // Dashboard
  dashboard: string = `${this.comm}/admin/dashboard`;

  // Zone
  getZones: string = `${this.driver1}/zone/getZones`;

  //Geofence
  getGeofence: string = `${this.driver1}/zone/geofence`;

  // CMS - Faq
  addFaq: string = `${this.driver1}/admin/addFaq`;
  getFaq: string = `${this.driver1}/admin/getAllFaq`;

  //  CMS - CRM
  addCrm: string = `${this.driver1}/admin/addCrm`;
  getCrm: string = `${this.driver1}/admin/getCrm`;

  // Sales Report
  itemWiseSalesPerAreaReport: string = `${this.driver1}/admin/itemWiseSalesPerAreaReport`;

  // PromoCodes (Deals and Coupans)
  // promoCode: string = `/admin/promocode`;
  // deletePromoCode: string = `/admin/deletePromoCode`;

  // Taxi
  getRevenueTaxi: string = `/admin/revenue/taxi`;

  // Membership Plans
  addMembershipPlan: string = `${this.driver1}/admin/addMembershipPlan`;
  getAllMembershipPlan: string = `${this.driver1}/admin/getAllMembershipPlan`;
  getMembershipPlanById: string = `${this.driver1}/admin/getMembershipPlanById`;
  deleteMembershipPlan: string = `${this.driver1}/admin/getMembershipPlanById`;
  editMembershipPlan: string = `${this.driver1}/admin/editMembershipPlan`;

  // Vehical Types
  vehicalTypes: string = `${this.driver1}/admin/vehicletype`;
  deleteVehicleType: string = `${this.driver1}/admin/driver/deleteVehicleType`;
  driverVehicalTypes: string = `${this.driver1}/driver/vehicletypes`;

  // Driver
  driver: string = `${this.driver1}/admin/driver/detail`;
  addWalletMoney: string = `${this.driver1}/admin/driver/addWalletMoney`
  addDriver: string = `${this.driver1}/driver/register`;
  getDriverList: string = `${this.driver1}/admin/driver/all`;
  getDriverOrderCount: string = `${this.driver1}/admin/driver/getDriverOrderCount`;
  getDriverRatings: string = `${this.driver1}/admin/driver/getDriversRatings`;
  updateDriverStatus: string = `${this.driver1}/admin/driver/blockUnblockDriver`;
  driverDocument: string = `${this.driver1}/admin/driver/document`;
  verifydocument: string = `${this.driver1}/admin/driver/verifydocument`;
  driverByStatus: string = `${this.driver1}/admin/driver/driverByStatus`;
  getAllVerticleTypes: string = `${this.driver1}/driver/vehicletypes`;
  getAllDriverCSV: string = `${this.driver1}/admin/getAllDriverCSV`;
  bulkUploadDriver: string = `${this.driver1}/admin/driver/bulkUploadDriver`;
  getAllDriverExport: string = `${this.driver1}/admin/driver/getAllDriverExport`;

  // Admin Documents
  getDocs: string = `${this.driver1}/admin/document`;

  getNotification: string = `${this.driver1}/admin/getNotification`;

  // Payments
  getDriverTotalCommsion: string = `${this.driver1}/admin/driver/getAllDriversTotalCommission`;
  getPaymentHistory: string = `${this.driver1}/admin/payHistory`;
  payStore: string = `${this.driver1}/admin/adminPay`;
  payRequestHistory:string = `${this.driver1}/admin/payRequestHistory`

  // Admin Settings New Modules -- Start
  prefence: string = `${this.driver1}/admin/prefence`;
  getAllReferal: string = `${this.driver1}/admin/getAllReferal`;
  manageReferral: string = `${this.driver1}/admin/manageReferral`;
  document: string = `${this.driver1}/admin/document`;
  getSeo: string = `${this.driver1}/admin/getSEO`;
  manageSeo: string = `${this.driver1}/admin/manageSeo`;
  generateToken: string = `${this.driver1}/admin/generateSecretTokan`;
  manageCustomerSetting: string = `${this.driver1}/admin/customerSettingMang`;
  getCustomerSetting: string = `${this.driver1}/admin/getCustomerSetting`;
  countryJsonThirdParty: string = `https://openexchangerates.org/api/currencies.json`;
  webhooks: string = `/admin/driver/webhook`;

  // Subscribe To Notification
  // subscribeToNotification: string = `/store/subscribe`;

  //store
  storeType: string = `${this.driver1}/admin/driver/storeType`;
  store: string = `${this.driver1}/admin/driver/store`;

  // Assign Order
  AssignOrder = `${this.driver1}/admin/freeDriverLists`;
  assignDriverManually = `${this.driver1}/admin/assignDriverManually`;

  // Warehouse
  warehouse: string = `${this.driver1}/admin/warehouse`;
  getAllWarehouse: string = `${this.driver1}/admin/getAllWarehouse`;
  getWarehouseOrders: string = `${this.driver1}/admin/store/getOrdersWarehouse`;
}

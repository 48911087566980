import { Injectable } from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { InitialService } from "./initial.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

function _window(): any {
  return window;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  get nativeWindow(): any {
    return _window();
  }
  oderStatus: string = "pending";
  storeOderStatus: string = "pending";
  dropSetting = {
    singleSelection: false,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  singleDropSetting = {
    enableCheckAll: false,
    singleSelection: true,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  currencyOptions = [
    { code: "SAR", symbol: "SAR" },
    { code: "INR", symbol: "₹" },
    { code: "KES", symbol: "K" },
    { code: "USD", symbol: "$" },
  ];
  keyForTaxi = [
    { displayeName: "taxi", moduleName: "taxi" },
    { displayeName: "shuttle", moduleName: "taxi" },
  ];
  parentModules: Array<any> = [
    { key: "onDemand", displayName: "On Demand" },
    { key: "taxi", displayName: "Taxi" },
    { key: "delivery", displayName: "Delivery" },
    { key: "finance", displayName: "Finance" },
  ];

  baseUrl;
  imageUrl;
  socketUrl;

  pendingOrderCount$ = new Subject<number>();
  geofenceChnaged$ = new Subject<string>();
  profileData$ = new Subject<string>();
  appSettings = new Subject<string>();
  isGeofenceAvailable$ = new Subject<string>();
  orderStatus = new Subject<string>();
  orderstatus2 = new Subject<string>();

  constructor(
    private toaster: ToastrManager,
    private http: HttpClient,
    private initial: InitialService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.getCountryCode();
    let data = this.initial.getBaseUrl();
    this.baseUrl = data.baseUrl;
    this.imageUrl = data.imageUrl;
    this.socketUrl = "https://demo.appdukaan.com:5013/"
  }

  checkPermission(name) {
    var admin = JSON.parse(localStorage.getItem("admin"));
    let data;
    admin.permissions.forEach((element) => {
      if (element.name == name && element.childs.length == 1) {
        data = {
          isView: element.childs[0].view,
          isEdit: element.childs[0].edit,
          isDelete: element.childs[0].delete,
        };
      }
    });
    return data;
  }

  isThirdParty() {
    return this.getGreatSetting()["appType"].length == 0 ? true : false;
  }

  getAppSetting() {
    return JSON.parse(localStorage.getItem("appSettings"));
  }

  getGreatSetting() {
    return JSON.parse(localStorage.getItem("greatSettings"));
  }

  getmoduleNameByKey(moduleKey) {
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        moduleName = element.moduleName;
      }
    });
    return moduleName;
  }

  getVerticalTypeByModuleKey(moduleKey) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        (displayName = element.displayName), (moduleName = element.moduleName);
      }
    });
    let verticalType;
    if (
      moduleName == "foodDeliveryApp" ||
      moduleName == "groceryApp" ||
      moduleName == "pharmacy" ||
      moduleName == "homeservice" ||
      moduleName == "ecommerce"
    ) {
      verticalType = 1;
    } else if (moduleName == "taxi" || moduleName == "basicDeliveryApp") {
      verticalType = 2;
    } else if (moduleName == "shuttle") {
      verticalType = 3;
    }
    return verticalType;
  }

  checkPermissionwithchildren(moduleKey, name) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        (displayName = element.displayName), (moduleName = element.moduleName);
      }
    });
    var admin = JSON.parse(localStorage.getItem("admin"));
    let data;
    admin.permissions.forEach((element) => {
      if (element.parent == true) {
        if (element.name == displayName) {
          if (moduleName == "foodDeliveryApp" && name == "Merchant Types") {
            name = "Cuisine Types";
          } else if (moduleName == "foodDeliveryApp" && name == "Categories") {
            name = "Food categories";
          } else if (
            moduleName == "foodDeliveryApp" &&
            name == "Manage Merchants"
          ) {
            if (greatSettings.appType.vendorType == "single")
              name = "Manage Cuisine Products";
            else {
              name = "Manage Restaurants";
            }
          }

          var childs = element.childs;
          childs.forEach((ele) => {
            if (ele.childName == name) {
              data = {
                isView: ele.view,
                isEdit: ele.edit,
                isDelete: ele.delete,
              };
            }
          });
        }
      }
    });
    return data;
  }

  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  successToast(message) {
    this.toaster.successToastr(message, "", {
      maxShown: 1,
      toastTimeout: 3000,
    });
  }
  errorToast(message) {
    this.toaster.errorToastr(message);
  }

  getFromLocalStorage(varName) {
    return JSON.parse(localStorage.getItem(varName));
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.appSettings.next("added");
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.appSettings.next("removed");
  }

  // confirm() {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: 'vggggggg',
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085D6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes",
  //     allowOutsideClick: false,
  //   }).then();

  confirmToast(text) {
    return Swal.fire({
      title: "Are you sure?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }
  confirmToastWithTittle(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }

  confirmToastDynamicTitleTextIcon(title, text, icon) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
  }

  sucessDialog(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }

  logOut() {
    let greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    let appSettings = JSON.parse(localStorage.getItem("appSettings"));
    localStorage.clear();
    localStorage.setItem("greatSettings", JSON.stringify(greatSettings));
    localStorage.setItem("appId", JSON.stringify(greatSettings["appId"]));
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
    this.router.navigateByUrl("/auth/login");
  }
}
